.error404 {
  padding-top: 150px;
  width: 100%;
  height:100%;
  //background-color:black;
  text-align: center;
  &-img {
    width:300px;
  }
  &-text {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 200%;
  }
  &-link {
    color:grey;
    font-size: 120%;
    width:100%;
    height:500px;
  }
}

//@use 'src/styles/vars';
//
//.error404{
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//  justify-content: center;
//  height: calc(100vh - 12rem);
//  background-color: #1a1a1ac0;
//  width: 95%;
//  margin: auto;
//  height: vars.$screen_height;
//  left: 2.5%;
//
//  border-radius: 5px;
//  box-shadow: 1px 1px 1rem rgb(223, 126, 0);
//  position: static;
//  overflow: hidden;
//
//  &__link {
//    color: #ff901f;
//    font-style: italic;
//
//  }
//}
//:root {
//  --animation: glitch; /* glitch, hue, fade, shrink */
//  --speed: 2s;
//  --easing: linear;
//}
//
//@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
//
//// html, body {
////   display: flex;
////   margin: 0;
////   height: 100%;
////   width: 100%;
////   align-items: center;
////   justify-content: center;
////   background: #111;
////   overflow: hidden;
//// }
//
//.gameover {
//  padding: 1em;
//  font-family: "Press Start 2P", Arial, sans-serif;
//  text-transform: uppercase;
//  text-align: center;
//  color: #2f99ff;
//  text-shadow: 0px 2px #255fcc;
//  animation: var(--animation) var(--speed) infinite var(--easing);
//  
//  & h1 {
//    font-size: 5em;
//  }
//}
//
//
//@keyframes glitch {
//  0%, 12%, 15%, 52%, 55%, 82%, 85%, 100% { opacity: 1; transform: scaleX(1) scaleY(1) }  
//  13% { opacity: .8; transform: scaleY(2); color: #0ff }
//  53% { opacity: .8; transform: scaleX(.7);  color: #f0f }
//  83% { opacity: .8; transform: rotate(-10deg); color: #ff0 }
//}
//
//@keyframes hue {  
//  13% { color: #0ff }
//  53% { color: #f0f }
//  83% { color: #ff0 }
//}
//
//@keyframes shrink {  
//   0% { transform: scaleY(1) }
//  50% { transform: scaleY(.5) }
// 100% { transform: scaleY(1) }
//}
//
//@keyframes fade {  
//   0% { opacity: 1 }
//  50% { opacity: 0 }
// 100% { opacity: 1 }
//}
//
//@keyframes fall {
//  0% { top: -50px}
//  100% { top: 40%}
//
//}
//